import { type ReactNode } from 'react'

interface PopupBodyProps {
  className?: string
  children: ReactNode
}
export function PopupBody({ className = '', children }: PopupBodyProps) {
  return (
    <div className={`bg-transparent w-full px-[12px] mb-1 ${className}`}>
      {children}
    </div>
  )
}
PopupBody.displayName = 'PopupBody'

export default PopupBody
