/* eslint-disable @typescript-eslint/no-explicit-any */

import { useAuth, useStore } from '@/containers'
import { nonAccentVietnamese } from '@/utils/converter'
import { useEffect, useState } from 'react'
import { SearchInput } from '../SearchInput'
import PopupBody from './PopupBody'
import PopupFrame from './PopupFrame'
import PopupTitle from './PopupTitle'

interface ModalChooseLocation {
  productName: string
  productId: string
}

const ModalChooseLocation = () => {
  const { setRefetch } = useAuth()
  const {
    regions,
    saleRegion,
    setSaleRegion,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
  } = useStore()
  const [choose, setChoose] = useState(saleRegion)
  useEffect(() => {
    if (saleRegion) {
      setChoose(saleRegion)
    }
  }, [saleRegion])
  const [location, setLocation] = useState('')
  return (
    <PopupFrame
      open={isOpenPopupLocation}
      onClose={() => {
        setIsOpenPopupLocation(false)
        setLocation('')
        localStorage?.setItem('Area-Code', 'true')
      }}
      type='choose_location'
      className='w-full bg-white-400'
    >
      <PopupTitle className='px-1'>
        {
          <div className='flex flex-wrap'>
            <p className='text-fieldLabel font-bold mb-3 pt-1'>
              Chọn Tỉnh/Thành phố
            </p>
            <SearchInput
              placeholder='Tìm nhanh tỉnh/thành phố'
              className='mb-3'
              value={location}
              onChange={e => setLocation(e.target?.value)}
            />
          </div>
        }
      </PopupTitle>
      <PopupBody>
        <div className='listLocation flex overflow-y-auto h-full pr-2 pl-0 pb-10 flex-row min-h-[calc(55vh-108px)] max-h-[calc(55vh-108px)] content-start scrollBarCS flex-wrap justify-start gap-y-2 border-gray-100 '>
          {regions &&
            regions
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .filter(store =>
                nonAccentVietnamese(store.name).includes(
                  nonAccentVietnamese(location)
                )
              )
              .map((item, index) => (
                <button
                  key={index}
                  className={`${
                    choose === item.code && 'text-bold'
                  } h-10 w-full border-b border-gray overflow-hidden outline-none box-content block px-3`}
                  onClick={async () => {
                    setRefetch(true)
                    setIsOpenPopupLocation(false)

                    if (saleRegion !== item.code) {
                      await localStorage?.removeItem('province')
                    }
                    await setSaleRegion(item.code)
                    await localStorage?.setItem('Area-Code', 'true')
                    setRefetch(false)
                    if (window.location.search.includes('shippingAddress')) {
                      const url = new URL(window.location.href)
                      url.searchParams.delete('shippingAddress')
                      url.searchParams.delete('pis')
                      window.history.pushState({}, '', url.toString())
                    }
                    window.location.reload()
                  }}
                >
                  <div className='flex items-center'>
                    {/* <span
                      className={`${
                        choose === item.code ? ' w-[30px]' : ' w-[30px]'
                      } flex-[30px] max-w-[30px]`}
                    >
                      {choose === item.code ? (
                        <SelectedIcon />
                      ) : (
                        <span className='block h-[16px] w-[16px] overflow-hidden border border-black-400 rounded-[100%] '></span>
                      )}
                    </span> */}
                    <div className='flex-1 flex flex-row text-center justify-start items-start flex-wrap gap-[2px]'>
                      <p
                        className={` block w-full text-mainBody text-dark-500 ${
                          choose === item.code &&
                          'font-medium text-primary-base'
                        }`}
                      >
                        {item.name}
                      </p>
                      {/* <p className='text-textSmall block w-full text-dark-500'>
                        Xem sản giá, chương trình và tồn kho thuộc khu vực này
                      </p> */}
                    </div>
                  </div>
                </button>
              ))}
        </div>
      </PopupBody>
    </PopupFrame>
  )
}

export default ModalChooseLocation
