import { SearchIcon } from '@/styles/Icon'
import { type ChangeEvent } from 'react'
interface SearchInputProps {
  placeholder?: string
  className?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
}
export const SearchInput = ({
  placeholder,
  className,
  onChange,
  value,
}: SearchInputProps) => {
  return (
    <div
      className={`flex w-full items-center relative px-2 overflow-hidden rounded-lg text-dark-400 outline-none transition bg-white-500 ${className}`}
    >
      <span className='prefix block absolute top-[50%] translate-y-[-50%] left-3 z-10'>
        <SearchIcon />
      </span>
      <input
        placeholder={placeholder}
        className='!h-10 w-full text-dark-400 outline-none bg-transparent px-4 pl-8 text-sm placeholder:font-light placeholder:text-sm placeholder:color-[#9c9c9c]'
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
