import { IconHome } from '@/components/Icon/HeaderIcon'
import ImageOptimize from '@/components/ImageOptimize'
import { PageType } from '@/constants'
import { useNotification, useStore } from '@/containers'
import { useRouterBack } from '@/hooks/useRouterBack'
import { IconBack, IconBackWhite, SearchIconBlue } from '@/styles/Icon'
import Link from 'next/link'
import { useMemo } from 'react'
interface HeaderType {
  layout: string
  page: string
  theme?: string
  dataHeader: string | undefined
  btnHome?: boolean
}
const Header = ({
  layout,
  dataHeader,
  btnHome = true,
  page,
  theme,
}: HeaderType) => {
  const { systemConfig } = useNotification()
  const handleGoBack = useRouterBack()
  const { regions, saleRegion, setIsOpenPopupLocation } = useStore()
  const renderInput = useMemo(() => {
    const store = regions.find(e => e.code === saleRegion)
    return (
      <div
        onClick={() => setIsOpenPopupLocation(true)}
        className='flex justify-center h-[48px] w-full cursor-pointer items-center gap-[6px]'
      >
        <span className='text-xs text-white leading-tight inline-block text-center'>
          {store?.name}{' '}
          <svg
            width='8'
            height='5'
            viewBox='0 0 8 5'
            className='inline-block'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M4 5L7.4641 0.714286H0.535898L4 5Z' fill='white' />
          </svg>
        </span>
        {/* <span className='max-w-[8px] flex-[8px]'></span> */}
      </div>
    )
  }, [regions, saleRegion])
  const headerDefault = useMemo(() => {
    return (
      <div className='flex h-[60px] items-center justify-between px-1 w-full bg-primary-base'>
        <div className='flex-[40px] max-w-[40px]'>
          <button
            className='h-10 w-10 outline-none flex justify-center items-center'
            onClick={() => handleGoBack(true)}
          >
            {theme == 'dark' ? <IconBackWhite /> : <IconBack />}
          </button>
        </div>

        <div className='flex-1 w-full px-1'>
          <h1 className='text-headingMedium break-all text-white overflow-hidden text-ellipsis  line-clamp-1'>
            {dataHeader}
          </h1>
        </div>
        {btnHome ? (
          <div className='flex-[40px] max-w-[40px]'>
            <Link
              className='h-10 w-10 outline-none flex justify-center items-center'
              href={'/'}
            >
              <IconHome />
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }, [dataHeader])
  return (
    <>
      {layout == PageType.HOMEPAGE && (
        <header
          className={`sticky bg-primary-base left-[50%] top-0 z-[999] flex h-[63px] pt-2 w-full max-w-[440px] items-center justify-between`}
        >
          <div
            className={`flex w-full items-center justify-between gap-2 px-2`}
          >
            <div className='relative mr-2 max-w-[96px] flex-[0_0_96px] md:max-w-[109px] md:flex-[0_0_109px] text-[0px]'>
              {page && page == 'homepage' ? (
                <h1 className='block'>
                  <ImageOptimize
                    loading='eager'
                    priority
                    className='h-auto w-full'
                    alt={systemConfig?.websiteBrandName || 'Logo'}
                    src={systemConfig?.companyLogoHeaderMobile || ''}
                    width={109}
                    height={28}
                    quality={100}
                  />
                </h1>
              ) : (
                <div className='block'>
                  <ImageOptimize
                    loading='eager'
                    priority
                    className='h-auto w-full'
                    alt={systemConfig?.websiteBrandName || 'Logo'}
                    src={systemConfig?.companyLogoHeaderMobile || ''}
                    width={109}
                    height={28}
                    quality={100}
                  />
                </div>
              )}
            </div>
            <Link
              href={'/search'}
              className='flex h-[40px] w-full flex-1 items-center justify-between gap-2 overflow-hidden rounded-lg bg-white py-2 pl-2 pr-2'
            >
              <SearchIconBlue />
              <span className='flex-1 text-[13px] text-gray-900 md:text-sm '>
                Bạn cần tìm gì hôm nay?
              </span>
            </Link>
            <div className='relative max-w-[64px] flex-[64px]'>
              {renderInput}
            </div>
          </div>
        </header>
      )}

      {layout == '' && headerDefault}
    </>
  )
}
export { Header }
