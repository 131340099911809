interface PopupTitle {
  className?: string
  children: React.ReactNode
}

function PopupTitle({ children, className = '' }: PopupTitle) {
  return (
    <div className={`type-headingMedium-medium ${className}`}>{children}</div>
  )
}
PopupTitle.displayName = 'PopupTitle'
export { PopupTitle }
export default PopupTitle
