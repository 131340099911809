import Keyword from '@/components/Keyword'
import OnDevelopment from '@/components/OnDevelopment'
import ModalChooseLocation from '@/components/Popup/ModalChooseLocation'
import PopupChat from '@/components/PopupChat'
import { PageType } from '@/constants'
import { useStore } from '@/containers'
import BottomNavigation from '@/layouts/BottomNavigation'
import { Footer } from '@/layouts/Footer'
import { Header } from '@/layouts/Header'
import type { ReactNode } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

type IMainProps = {
  meta?: ReactNode
  children: ReactNode
  dataHeader?: string | ''
  pageLayout?: string | ''
  btnHome?: boolean
  bgColor?: string | ''
  bgImage?: object
  visible?: boolean
  hideMenu?: boolean
  page?: string
  showFooter?: boolean
  theme?: string
}
const Main = (props: IMainProps) => {
  const { keywords } = useStore()
  return (
    <>
      {props.meta}
      <img
        width={999}
        alt=''
        loading='eager'
        height={9999}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '99vw',
          height: '99vh',
          maxWidth: '99vw',
          maxHeight: '99vh',
        }}
        src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+'
      />

      <div className='w-full text-dark-200 antialiased scrollbar-hide'>
        {!props.hideMenu &&
          props.pageLayout != PageType.PRODUCT &&
          props.pageLayout != PageType.CHECKOUT &&
          props.pageLayout != PageType.SEARCH &&
          props.pageLayout != PageType.CATEGORY && (
            <Header
              layout={props.pageLayout as string | ''}
              dataHeader={props.dataHeader as string | ''}
              btnHome={props.btnHome as boolean | undefined}
              page={props?.page || ''}
              theme={props?.theme || ''}
            />
          )}

        <main
          className='main-content text-sm'
          style={{ backgroundColor: props.bgColor, ...props.bgImage }}
        >
          <div
            className={`${
              props.pageLayout == PageType.HOMEPAGE ? 'z-10' : ''
            } mainWrapper relative mx-auto w-full`}
          >
            {props.children}
          </div>
        </main>
        <PopupChat />
        {props.showFooter && <Keyword data={keywords} />}
        {props.showFooter && <Footer />}
        <BottomNavigation
          layout={props.pageLayout as string | ''}
          page={props.page || ''}
        />
        <OnDevelopment />
        <ModalChooseLocation />
      </div>
    </>
  )
}

export { Main }
