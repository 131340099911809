import { IconClose } from '@/styles/Icon'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo, useRef } from 'react'
export interface PopupFrame {
  className?: string
  popupBodyClassName?: string
  open: boolean
  children: JSX.Element[]
  onClose: () => void
  type?: string
}

export function PopupFrame({
  className,
  popupBodyClassName = '',
  open = false,
  children,
  onClose,
  type,
}: PopupFrame) {
  const titleChild = useMemo(
    () => children.filter(f => f.type.displayName === 'PopupTitle'),
    [children]
  )
  const bodyChild = useMemo(
    () =>
      children.filter(f => f.type.displayName === ('PopupBody' || 'FormGroup')),
    [children]
  )
  const closeRef = useRef(null)
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={closeRef}
        as='div'
        className={`${type ? type : ''} relative z-[9999]`}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-40'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-40'
        >
          <div className='fixed inset-0 left-0 top-0 bg-black-100 transition-opacity' />
        </Transition.Child>
        <div
          className={`${popupBodyClassName} pointer-events-none z-50 overflow-hidden fixed left-[50%] translate-x-[-50%] bottom-0 flex max-h-[55vh] h-full max-w-[440px]`}
        >
          <Transition.Child
            as={Fragment}
            enter='transform transition ease-in-out duration-500'
            enterFrom='translate-y-[100%]'
            enterTo='translate-y-[0%]'
            leave='transform transition ease-in-out duration-500'
            leaveFrom='translate-y-[0%]'
            leaveTo='translate-y-[100%]'
          >
            <Dialog.Panel className='pointer-events-auto w-screen max-w-md h-full shadow-modal'>
              <div
                className={`${className} bg-white z-[9999] inline-block transform overflow-hidden rounded text-left align-middle shadow-xl transition-all`}
              >
                <Dialog.Title
                  as='div'
                  className={'relative bg-white px-2 pt-4'}
                >
                  {titleChild}
                  <div className='absolute right-0 top-0 cursor-pointer p-4 '>
                    <div onClick={onClose} ref={closeRef}>
                      <IconClose />
                    </div>
                  </div>
                </Dialog.Title>
                {bodyChild}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PopupFrame
