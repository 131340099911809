import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { useNotification } from '@/containers'
import type { MetaTag } from 'next-seo/lib/types'
interface ogType {
  title?: string
  description?: string
  url?: string
  images?: [
    {
      url?: string | ''
      alt?: string
    }
  ]
  site_name?: string
}
type IMetaProps = {
  title: string
  description: string
  canonical?: string
  imageUrl?: string
  index?: boolean
  follow?: boolean
  keywords?: string[]
  imageAlt?: string
  additionalMetaTags?: MetaTag[]
  openGraph?: ogType
}

const Meta = (props: IMetaProps) => {
  const router = useRouter()
  const { systemConfig } = useNotification()
  function removeAllQueryParams(url: string) {
    // Use URL class to parse the URL and get the base URL without query parameters
    const parsedUrl = new URL(url)
    const baseUrl = parsedUrl.origin + parsedUrl.pathname

    return baseUrl
  }
  return (
    <>
      <Head>
        <meta charSet='UTF-8' key='charset' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
          key='viewport'
          user-scalable='no'
        />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />

        <link
          rel='apple-touch-icon'
          href={`${router.basePath}/apple-touch-icon.png`}
          key='apple'
        />

        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={systemConfig?.companyLogoFavicon}
          key='icon32'
        />
        <link
          rel='icon'
          href={systemConfig?.companyLogoFavicon}
          key='favicon'
        />
        <script
          id='tiktok-ads'
          dangerouslySetInnerHTML={{
            __html: `!function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

              ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_ID}');
              ttq.page();
            }(window, document, 'ttq');`,
          }}
        />
        <script
          id='fb-pixel'
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
      fbq('track', 'PageView');`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1" />`,
          }}
        />
      </Head>
      <NextSeo
        title={props?.title}
        description={props?.description}
        canonical={removeAllQueryParams(
          props?.canonical || process.env.NEXT_PUBLIC_URL_WEBSITE || ''
        )}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: props?.keywords
              ? props?.keywords.join(',')
              : `${systemConfig?.websiteBrandName}, chuyên gia điện máy, điện tử, điện lạnh, điều hòa, tủ lạnh, máy giặt, đồ gia dụng, tivi, khuyến mại, giao hàng nhanh`,
          },
        ]}
        noindex={props?.index || false}
        nofollow={props?.follow || false}
        openGraph={{
          title: props?.title,
          description: props?.description,
          url: props?.canonical,
          images: [
            {
              url: props?.imageUrl
                ? props?.imageUrl
                : systemConfig
                ? systemConfig?.seoImageDefault
                : '',
              width: 800,
              height: 600,
              alt: props?.title,
            },
          ],
          site_name: process.env.NEXT_PUBLIC_URL_WEBSITE,
        }}
      />
    </>
  )
}

export { Meta }
